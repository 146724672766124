import React from "react";
import styles from '../styles/Benefits.module.css'

export default function Benefits() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>制度紹介</h2>
        <ul className={styles.list + ' grid grid-cols-2 text-xs md:text-sm md:grid-cols-3 text-center mt-10'}>
          <li>雇用保険</li>
          <li>労働保険</li>
          <li>健康保険(ITS)</li>
          <li>厚生年金</li>
          <li>有給休暇</li>
          <li>ベビーシッター費用補助</li>
          <li>書籍購入手当</li>
          <li>業務用備品の貸与</li>
          <li>資格取得支援</li>
          <li>勉強会支援</li>
          <li>髪型・服装自由</li>
          <li>業務開始時間調整可</li>
          <li>社員・パートナー交流会</li>
          <li>オフィスジム</li>
          <li>パーソナルトレーニング</li>
          <li>など</li>
        </ul>
      </div>
    </div>
  );
}
