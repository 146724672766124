import React from "react";
import { Helmet } from "react-helmet";
import Mission from '../recruitPage/components/mission';
import Benefits from '../recruitPage/components/benefits';
import Activities from '../recruitPage/components/activities';
import Footer from '../recruitPage/components/footer';
import styles from '../css/Recruit.module.css';

function Page() {
  const pageTitle = "Craftsman Software | 採用情報";
  const websiteUrl = "https://craftsman-software.com/recruit";
  const companyDescription =
    "Craftsman Softwareでは一緒に働く仲間を募集しています。"; // TODO: DatoCMSのSEO設定からデータを取得する

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={companyDescription} />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href="/apple-touch-icon-180x180.png"
        />
        <link rel="icon" type="image/png" href="/icon-192x192.png" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <script
          type="modlie"
          src="https://unpkg.com/ionicons@5.2.3/dist/ionicons/ionicons.esm.js"
        ></script>
        <script src="https://embed.typeform.com/embed.js"></script>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={companyDescription} />
        <meta property="og:url" content={websiteUrl} />
        <meta property="og:site_name" content="Craftsman Software" />
        <meta
          property="og:image"
          content="https://craftsman-software.com/ogp-recruit.png"
        />
        <meta property="og:locale" content="ja_JP" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://craftsman-software.com/ogp-recruit.png"
        />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={companyDescription} />
      </Helmet>
      <main>
        <div className="mt-6 mx-6">
          <a href="/">
            <img src="/logo.svg" alt="Craftsman Software" />
          </a>
        </div>
        <Mission/>
        <div className={styles.interview}>
          <div className={styles.inner}>
            <h2 className={styles.title}>クラフトマンソフトウェアで働く人たちに聞いてみた！</h2>
            <div>
              <h3>クラフトマンソフトウェアの魅力は？</h3>
              <ul className="list-none leading-10">
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">代表がエンジニアで、常に挑戦し成長できる文化</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">ITに関心の高い人が多く、入ってくる情報量が多い</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">最新のイケてる技術に触れることができる</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">資格等の技術習得に対する支援環境が圧倒的</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">椅子やディスプレイなど開発環境が充実している</li>
              </ul>
            </div>
            <div>
              <h3>会社やチームの雰囲気ってどんな感じ？</h3>
              <ul className="list-none leading-10">
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">意見をちゃんとぶつけ合い、より良くしようと真剣に考えている</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">上下関係がなくフラットで、成果主義</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">チームメンバーを尊重し、誰も置き去りにしないポリシー</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">みんなで良いものを作ろう！という考え方が浸透している</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">毎月懇親会を支援してくれて、皆と仲良くなれる！</li>
                <li className="text-white bg-blue-800 text-center rounded-lg py-2 px-4 mb-2">絵文字・リアクションで遊んでる</li>
              </ul>
            </div>
            <div>
              <h3>入社して身についた/成長したスキルは？</h3>
              <ul className="flex flex-wrap list-none">
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Kubernetes</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">AWS</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">OSS活動</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Go言語</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">スクラム開発</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Rust</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Argo Workflows</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Crossplane</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Terraform</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Next.js</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">ArgoCD</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">GitHub Actions</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Ceph</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">Karpenter</li>
                <li className="flex-auto text-white bg-blue-800 text-center rounded-lg py-2 px-4 mx-2 mb-2">自動化</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.wanted}>
          <div className={styles.inner}>
            <h2 className={styles.title}>応募について</h2>
            <p className="text-center font-bold text-green-500">社員・業務委託OK！</p>
            <p className="text-center font-bold text-green-500">カジュアル面談も随時実施中！お気軽にお問い合わせください</p>
            <a 
              href="https://herp.careers/v1/c16e" 
              className={styles.wanted_link + " block w-64 text-center font-bold tracking-widest text-white text-lg py-5 px-10 rounded-full mx-auto"}
            >
              応募する
            </a>
            <div>
              <h3>採用フロー</h3>
              <ol className="list-none p-0">
                <li className="bg-white p-3 mb-3 rounded-lg">
                  <div className="inline-block mb-3 align-top border py-1 px-5 text-sm border-green-500 text-green-500">STEP 1</div>
                  <div className="inline-block ml-5">
                    <span className="text-lg text-blue-500 font-semibold">ご応募</span>
                    <p className="mt-2 mb-0 text-sm">募集職種一覧から希望の職種を選択し、「応募する」ボタンからご応募ください。</p>
                  </div>
                </li>
                <li className="bg-white p-3 mb-3 rounded-lg">
                  <div className="inline-block mb-3 align-top border py-1 px-5 text-sm border-green-500 text-green-500">STEP 2</div>
                  <div className="inline-block ml-5">
                    <span className="text-lg text-blue-500 font-semibold">カジュアル面談（希望者のみ）</span>
                    <p className="mt-2 mb-0 text-sm">書類提出は不要です。応募フォームにてカジュアル面談希望の旨をご入力ください。</p>
                  </div>
                </li>
                <li className="bg-white p-3 mb-3 rounded-lg">
                  <div className="inline-block mb-3 align-top border py-1 px-5 text-sm border-green-500 text-green-500">STEP 3</div>
                  <div className="inline-block ml-5">
                    <span className="text-lg text-blue-500 font-semibold">書類選考</span>
                    <p className="mt-2 mb-0 text-sm">いただいた書類を拝見し、選考をさせていただきます。</p>
                  </div>
                </li>
                <li className="bg-white p-3 mb-3 rounded-lg">
                  <div className="inline-block mb-3 align-top border py-1 px-5 text-sm border-green-500 text-green-500">STEP 4</div>
                  <div className="inline-block ml-5">
                    <span className="text-lg text-blue-500 font-semibold">採用面談</span>
                    <p className="mt-2 mb-0 text-sm">2〜3回の面談をオンラインで実施いたします。</p>
                  </div>
                </li>
                <li className="bg-white p-3 mb-3 rounded-lg">
                  <div className="inline-block mb-3 align-top border py-1 px-5 text-sm border-green-500 text-green-500">STEP 5</div>
                  <div className="inline-block ml-5">
                    <span className="text-lg text-blue-500 font-semibold">内定</span>
                    <p className="mt-2 mb-0 text-sm">応募から内定までは2〜3週間ほどかかります。ご了承ください。</p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <Benefits/>
        <Activities/>
      </main>
      <Footer />
    </div>
  );
}

export default Page;
